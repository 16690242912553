@import '../styles/index.scss';

.a__routes {
  .b__before {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding: 50px;
    overflow: scroll;
    color: $app__black-color;
    @include gc__bg_gradient($app__white-color, 60deg);
  }
  .b__sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 199px;
    height: 100vh;
    &.hide {
      display: none;
    }
  }

  .b__after {
    position: relative;
    left: 199px;
    flex: 1;
    width: 100vw;

    .b__header {
      width: calc(100% - 220px);
      height: 67px;
    }

    .b__main {
      position: absolute;
      z-index: 0;
      flex: 1;
      width: calc(100vw - 220px);
      height: auto;
      padding: 24px 8px;
    }
  }
}
