@import '../../../styles/index.scss';

.a__signIn {
  display: flex;
  flex: 1;

  .b__image {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: {
      right: 1%;
      left: 10%;
    }
  }

  .b__form {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: $app__black-color;

    padding: {
      right: 10%;
      left: 1%;
    }

    .title {
      margin: {
        bottom: 20px;
      }
    }

    .w__form {
      display: flex;
      flex-direction: column;
      width: 80%;

      .w__field {
        margin: {
          bottom: 10px;
        }
      }

      .messages {
        font: {
          size: 14px;
        }
        padding: {
          top: 6px;
        }
      }
    }
    .w__actions {
      padding: {
        top: 16px;
      }
    }
  }
}
