.b__stocks-detail__form-main {
  width: 640px;
  padding: 20px 40px;
  background: #fff;
  border: 2px solid #ffc107;
  box-shadow: 0 0 30px #e9ecef;

  .b__field {
    margin-bottom: 20px;

    &__m0 {
      margin-top: -12px;
      margin-bottom: 20px;
    }

    &__row {
      display: flex;
    }

    &__column {
      display: flex;
      align-items: center;
      width: 100%;
      padding-bottom: 8px;

      span {
        margin-right: 3px;
      }

      @media screen and (min-width: 1224px) {
        width: 230px;

        span {
          margin-right: 8px;
        }
      }
    }

    textarea {
      background: #fff;
    }
  }

  .stack {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25em 1.25em;
    margin-top: 0.5em;
  }

  .a__dropzone {
    margin-bottom: 8px;
  }

  .b__dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 160px;
    text-align: center;
    cursor: pointer;
    border: rgba(204, 204, 204, 0.8) dashed 2px;
    border-radius: 5px;

    &__paragraph {
      font-size: 14px;
    }

    &.disabled {
      cursor: not-allowed;

      &:focus {
        outline: none;
      }
    }
  }

  .b__preview {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    list-style: none;

    &__wrapper {
      box-sizing: border-box;
      width: 100px;
      margin: 10px 5px 0;

      img {
        width: 100px;
        height: 100px;
        background: #fff;
        border: 1px solid hsla(0, 0%, 80%, 0.8);
        object-fit: contain;
      }

      button {
        width: 100%;
        font-weight: normal;
        color: #4183c4;
        text-align: center;
        background: none;
        border: none;
        outline: none;
      }

      .b__preview-delete.disabled {
        display: none;
      }
    }
  }

  .b__paragraph {
    text-align: right;
  }

  .form__error {
    font-size: 14px;
    color: #d44343;
    pointer-events: none;
  }

  .isNature {
    display: flex;

    .a__checkbox {
      width: 20%;
    }
  }

  .ikejimeWater {
    padding: {
      top: 8px;
    }
    display: flex;

    .a__checkbox {
      width: 20%;
    }
  }
}
