@import '../../../../styles/index.scss';

.a__header-after {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  padding: {
    right: 40px;
  }
  background: {
    color: $white;
  }
  border: {
    bottom: 1px $very-light-pink-two solid;
  }
  .ui.dropdown.b__user {
    .text {
      font-weight: bold;
    }
    .menu {
      position: absolute;
      top: 32px;
      border: none;
      box-shadow: 0 0 4px 0 $very-light-pink-three;
      .item {
        padding: {
          top: 8px !important;
          bottom: 8px !important;
        }
        .text {
          font-weight: normal;
        }
      }
    }
  }
}
