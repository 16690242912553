@import '../../../../styles/index.scss';

.block-input.ui.input {
  border-radius: 4px;
  box-shadow: 0 0 2px rgba($form__main-color, 0.2);

  &.outline {
    box-shadow: 0 0 2px rgba($form__main-color, 1);
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
  &.dark {
    border: none;

    input {
      background: {
        color: $form__dark-color;
      }
    }
  }

  input {
    height: 40px;
    border: none;
    border-radius: 4px;
    background: {
      color: $form__white-color;
    }
    padding: {
      top: 12px;
      right: 16px;
      left: 16px;
      bottom: 12px;
    }
    font-size: 16px;
    line-height: $app__line-height;
    &:focus {
      box-shadow: 0 0 0 3px rgba($form__main-color, 0.7);
      background: {
        color: $form__white-color;
      }
    }
    &:active {
      box-shadow: 0 0 0 3px rgba($form__main-color, 0.7);
      background: {
        color: $form__white-color;
      }
    }
  }

  .fluid {
    width: 100%;
  }
}
