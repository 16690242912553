@import './values';

.c__after-title {
  margin: {
    bottom: 20px;
  }
}

.c__before-title {
  margin: {
    bottom: 20px;
  }
}

.c__cant-click {
  pointer-events: none;
}

.c__hr {
  border: 0.3px solid $app__dark-color;
  margin: {
    bottom: 6px;
  }
}

.c__m-top-8 {
  margin-top: 8px;
}

.c__m-top-16 {
  margin-top: 16px;
}

.c__th {
  font-size: 12px;
  font-weight: bold;
}

.c__td {
  &.td {
    display: flex !important;
  }
  font-size: 14px;
}

.c__label {
  font-size: 12px;
  font-weight: bold;
}

.bottom10 {
  margin: {
    bottom: 10px;
  }
}
